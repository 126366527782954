.signinup-main-box{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 15px;
    overflow: auto;
    overflow-y: auto;
}

.signinup-screen-header{
    position: relative;
    display: none;
    margin-bottom: 15px;
    z-index: 1;
}

.signinup-screen .SignInUpBackBtn,
.signinup-screen-header .SignInUpBackBtn {
    position: absolute;
    top: calc(50% + 7.5px);
    left: 15px;
    padding: 0;
    height: 28px;
    width: 28px;
    z-index: 1;
    transform: translateY(-50%);
    color: var(--white);
    transition: all 0.3s ease-in-out;
}

.signinup-screen .SignInUpBackBtn{
    display: block;
    top: 25px;
    transform: inherit;
    color: var(--black-600);
}

.signinup-screen .SignInUpBackBtn:hover{
    color: var(--black);
}

.signinup-logo-bx{
    padding: 15px;
    display: block;
    width: fit-content;
    margin: auto;
}

.signinup-logo-bx img{
    width: 150px;
    object-fit: contain;
    object-position: center;
    margin: auto;
    display: block;
}

/* .signinup-logo-bx img.main-logo2{
    display: none;
    width: 160px;
} */


.signinup-width{
    width: 100%;
    margin: 15px auto;
    padding: 0 15px;
}

/* @media screen and (max-width:576px) {
    .signinup-width{
        max-width: 320px;
        width: 100%;
    }
} */


/* ---
Seconde Screen
--- */
.signinup-screen {
    max-width: 396px;
    width: 100%;
    position: relative;
    margin: auto;
    padding: 10px 0px 10px;
    background-color: var(--white);
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
}

/* ---
Signinup Link
--- */
.signinup-link-outer-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    font-size: 14px;
}

.slob-fdc{
    gap: 1px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.slob-fdc.fdc-two{
    gap: 5px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.signinup-link{
    color: var(--white-800);
    display: block;
    font-size: 12px;
    font-weight: 500;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    cursor: pointer;
}

.signinup-link:hover{
    color: var(--white);
    text-decoration: underline;
}

.signinup-link-two{
    cursor: pointer;
    color: var(--black-800);
    display: block;
    font-size: 14px;
    font-weight: 500;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.signinup-link-two:hover{
    color: var(--black);
    text-decoration: underline;
}

.signinup-link-outer-bx .signinup-link-two {
    text-decoration: underline;
}

/* ---
Screen heading
--- */
.signinup-header{
    margin-top: 8px;
    margin-bottom: 18px;
    padding: 0 15px;
}

.screen-hed {
    color: var(--black);
    font-weight: 700;
    font-size: 22px;
    text-align: left;
}

.screen-pera {
    color: var(--black-800);
    font-weight: 500;
    font-size: 15px;
    text-align: left;
    margin-top: 5px;
    opacity: 0.9;
}

.screen-pera .sp-otp-link{
    color: white;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.screen-pera .sp-otp-link:hover{
    color: #0294B5;
    text-decoration: underline;
}


@media screen and (max-width:576px) {
    .signinup-main-box{
        padding-top: 0;
        position: static;
        left: initial;
        top: initial;
    }

    /* .signinup-logo-bx img.main-logo2, */
    .signinup-screen-header{
        display: block;
    }

    .signinup-screen-header .signinup-logo-bx{
        padding-top: 30px;
        filter: invert();
    }

    /* .signinup-logo-bx img.main-logo, */
    .signinup-screen .SignInUpBackBtn,
    .signinup-screen .signinup-logo-bx{
        display: none;
    }

}