/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 1024 versions
*/

.bg-pricing-hero-next {
    background-image: -webkit-linear-gradient(245deg,#15bcdf4d,#15bcdf0d 50%,#30e9be4d);
    background-image: -moz-linear-gradient(245deg,#15bcdf4d,#15bcdf0d 50%,#30e9be4d);
    background-image: -o-linear-gradient(245deg,#15bcdf4d,#15bcdf0d 50%,#30e9be4d);
    background-image: linear-gradient(205deg,#15bcdf4d,#15bcdf0d 50%,#30e9be4d);
}

.bg-pricing-hero-next .text-plans{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-align: center;
    padding-left: .5rem;
    padding-right: .5rem;
}

.bg-pricing-hero-next .heading-start{
    font-size: 3rem;;
    font-weight: 700;
    letter-spacing: -.02em;
    line-height: 3.5rem;
    text-align: center;
}

.bg-pricing-hero-next .text-sub-choose{
    font-size:  1.375rem;
    letter-spacing: -.02em;
    line-height: 2rem;
    padding-top: 1rem;
    text-align: center;
}

.main-p-my{
    border: 2px solid var(--mint-Green);
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    padding: 4px;
    display: table;
    margin: auto auto 35px auto;
}

.main-p-my .nav-tabs{
   border-bottom: none;
}

.main-p-my .main-p-month,
.main-p-my .nav-link.active{
    font-size: 14px;
    color: var(--white);
    background-color: var(--black);
    border: 2px solid var(--black);
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    padding: 5px 18px 5px 18px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    cursor: pointer;
}

.main-p-my,
.main-p-my a{
    color: var(--black);
}

.main-p-my .main-p-month,
.main-p-my .main-p-year,
.main-p-my .main-p-month a,
.main-p-my .main-p-year a{
    font-size: 14px;
    /* background-color: transparent;
    border: 2px solid transparent; */
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    padding: 5px 18px 5px 18px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-transition-timing-function: cubic-bezier(.4,0,.2,1);
       -moz-transition-timing-function: cubic-bezier(.4,0,.2,1);
         -o-transition-timing-function: cubic-bezier(.4,0,.2,1);
            transition-timing-function: cubic-bezier(.4,0,.2,1);
    -webkit-transition-duration: .15s;
       -moz-transition-duration: .15s;
         -o-transition-duration: .15s;
            transition-duration: .15s;
}

.main-p-my .main-p-year:hover,
.main-p-my .nav-link:hover{
    border-color: transparent;
    background-color: var(--black-100);
    color: var(--black);
    cursor: pointer;
}

.main-pricing-bx{
    background-color: var(--white);
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    -webkit-filter: drop-shadow(0 20px 13px rgba(0, 0, 0, .03)) drop-shadow(0 8px 5px rgba(0, 0, 0, .08));
            filter: drop-shadow(0 20px 13px rgba(0, 0, 0, .03)) drop-shadow(0 8px 5px rgba(0, 0, 0, .08));
}

.main-pricing-bx .main-i-text{
    background-color: var(--mint-Green);
    -webkit-border-radius: 10px 10px 0px 0px;
       -moz-border-radius: 10px 10px 0px 0px;
            border-radius: 10px 10px 0px 0px;
    text-align: center;
    font-size: 14px;
    padding: 5px;
}

.main-pricing-bx .grid{
    display: -ms-grid;
    display: grid;
}

.main-pricing-bx .grid .main-hea-secn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 12px;
}

.main-pricing-bx .grid .main-heading-pricing{
    letter-spacing: -0.4px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
}

.main-pricing-bx .grid .bg-starter-green{
    background-color: var(--mint-Green);
    font-size: 11px;
    line-height: 20px;
    font-weight: 400;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    white-space: nowrap;
    padding: 15px;
}

.main-pricing-bx .grid .main-head-text{
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
}

.main-pricing-bx .grid .billed-text{
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}

.main-pricing-bx .grid .once-yearly{
    font-size: 45px;
    font-weight: 700;
    letter-spacing: -0.4px;
    line-height: 1;
    padding-right: 5px;
}

.main-pricing-bx .grid .bil-onc-yea{
    color: #27272a;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    bottom: -7px;
    position: relative;
    vertical-align: baseline;
}

.main-pricing-bx .grid .once-y-text{
   color: #71717a;
   font-size: 14px;
    line-height: 16px;
    width: 100%;
    display: block;
    margin-top: 4px;
}

.main-pricing-bx .card-rates-text{
    border-left-width: 1px;
    border-right-width: 1px;
    font-size: 14px;
    line-height: 20px;
    padding-left: 16px;
    padding-right: 16px;
    background-color:var(--white);
    border-color:var(--white-100);
    border-style: solid;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
}

.main-pricing-bx .card-rates-text .card-rat-head{
    font-weight: 600;
    white-space: nowrap;
}

.main-pricing-bx .card-rates-text ul{
    gap: 8px;
    display: -ms-grid;
    display: grid;
    list-style: none;
    margin: 0;
    padding: 0;
}

.main-pricing-bx .card-rates-text li{
    gap: 10px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.main-pricing-bx .card-rates-text svg{
    width: 15px;
    height: 15px;
    position: relative;
    top: 2px;
}

.main-pricing-bx .card-rates-text .cbi-white{
    color: var(--white);
    background-color: var(--black);
}

.main-pricing-bx .card-rates-text .cbi-dms-btn {
    width: 100%;
    font-size: 18px;
    padding: 12px 24px;
    height: 50px;
    margin-top: 24px;
}

.main-pricing-bx .card-rates-text .common-btn-item {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 54px;
       -moz-border-radius: 54px;
            border-radius: 54px;
    min-width: 100px;
    color: var(--white);
    font-weight: 500;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.main-pricing-bx .card-rates-text .cbi-white:hover{
    color: var(--white);
    background-color: var(--black-700);
}

.plans-size{
    display: none;
}

@media screen and (max-width:992px){

.tabs-sect{
    display: none;
}

.plans-size{
    display: block;
}

.plans-account-size-btns{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
}

.plans-account-size-btns .pasb-item {
    letter-spacing: -.025em;
    padding-bottom: 0;
    padding-top: 0;   
    font-weight: 700;
    font-size: .875rem;
    line-height: 1.25rem;
    text-align: center;
    border-right-width: 1px var(--black-700);
    border-top-width: 1px var(--black-700);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    height: 3.5rem;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0%;
       -moz-box-flex: 1;
        -ms-flex: 1 1 0%;
            flex: 1 1 0%;
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
    margin-top: auto;
}

.plans-account-size-btns .pasb-item.active .plans-accou-mon{
    background-color:var(--mint-Green);
    width: 150px;
    height: .25rem;
    top: 0;
    position: absolute;
}

}