.custome-footer{
    background-color: var(--black);
}

.cf-logo {
    filter: invert(1);
    margin-bottom: 20px;
}

 .cf-logo img {
    width: 160px;
    margin: 0px auto 30px auto;
    display: block;
}

.footer-heading {
    text-align: center;
    color: var(--white);
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 0;
}

.footer-links-bx .footer-link {
    color: var(--white-500);
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 0;
    margin-top: 5px;
    position: relative;
    transition: all .4s ease-in-out;
}

.footer-links-bx .footer-link.active, .footer-links-bx .footer-link:hover {
    color: var(--white);
}

.footer-links-bx .footer-link:after {
    background-color: var(--white-500);
    content: " ";
    height: 50%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
}

.footer-links-bx{
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    /* margin: 0 -8px; */
    text-align: center;
}

.footer-links-bx .footer-link{
    display: inline-block;
    padding: 5px 10px 5px 10px;
}

.footer-links-bx .footer-link::after{
    content: " ";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 1px;
    height: 50%;
    background-color: var(--white-200);
}

.footer-links-bx .footer-link:last-child::after {
    content: none;
}

/* --- Footer Contact Info Css --- */
.footer-contact-info{
    justify-content: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    padding: 8px 0px;
    margin-top: 5px;
    font-size: 14px;
    color: var(--white-500);
    /* padding-right: 10px; */
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.footer-contact-info.fci-nowrap{
    white-space: nowrap;
}

.footer-contact-lable{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
    margin-bottom: -7px;
    font-size: 12px;
    color: var(--celeste-800);
    transition: all 0.3s ease-in-out;
}

.footer-contact-lable img{
    width: 25px;
    height: 15px;
    object-position: left center;
    object-fit: contain;
    border-radius: 1px;
}

.footer-contact-info img{
    position: relative;
    top: 1px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    opacity: 0.8;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.footer-contact-info:hover{
    color: var(--white);
}

.whatsapp-link .footer-contact-info img,
.footer-contact-info:hover img{
    opacity: 1;
}

.social-icon.si-black .si-item .si-icon{
    color: var(--white-700);
    background-color: var(--white-100);
}

.social-icon.si-black  .si-item:hover .si-icon {
    color: var(--black);
    background-color: var(--white);
}


/* --- Footer copyright & mede-with section Css --- */
.footer-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0px 0 15px;
}

.copyright {
    margin-top: 30px;
    color: var(--white-700);
    font-size: 14px;
}

.mede-with {
    color: var(--white);
    font-size: 14px;
    margin: 0;
}

.mede-with a {
    color: var(--white-600);
    font-weight: 300;
    opacity: 0.8;
}

.mede-with:hover a,
.mede-with a:hover {
    opacity: 1;
    color: var(--white);
}

.icons-box-made {
    width: 20px;
    height: 14px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    top: 3px;
}

.icons-box-made .fa{
    position: absolute;
}

.mede-with .fa:nth-child(1) {
    color: var(--white-600);
    font-size: 12px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 1;
}

.mede-with .fa:nth-child(2) {
    color: var(--white-600);
    font-size: 12px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0;
    -webkit-transform: scale(0);
       -moz-transform: scale(0);
        -ms-transform: scale(0);
         -o-transform: scale(0);
            transform: scale(0);
}

.mede-with:hover .fa:nth-child(1) {
    opacity: 0;
}

.mede-with:hover .fa:nth-child(2) {
    opacity: 1;
    color: var(--white);
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
        -ms-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1);
}

.footer-links-bx .footer-link img{
   opacity: 0.7;
}

.footer-links-bx .footer-link:hover img{
   opacity: 1;
}

.footer-links-bx .footer-link span{
    margin-left: 10px;
}

.footer-links-bx .footer-link.fci-nowrap::after{
    content: " ";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 1px;
    height: 50%;
    background-color: var(--white-100);
}


@media screen and (max-width:700px) {
    .footer-bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }

    .copyright {
        margin-bottom: 10px;
    }
}


@media screen and (max-width:768px) {

    .footer-links-bx{
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        margin: 0 -8px;
    }

    .footer-links-bx .footer-link{
        display: inline-block;
        padding: 5px 10px 5px 10px;
    }
    
    .footer-links-bx .footer-link::after{
        content: " ";
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
           -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
             -o-transform: translateY(-50%);
                transform: translateY(-50%);
        width: 1px;
        height: 50%;
        background-color: var(--white-100);
    }
    
    .footer-links-bx .footer-link:last-child::after {
        content: none;
    }
}
