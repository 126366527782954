html {
    scroll-behavior: initial !important;
}

* {
    text-decoration: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a,
a:hover {
    text-decoration: none;
    color: var(--black);
}

.common-word-wrap {
    word-wrap: break-word;
}

/* --- Body tag css --- */
body {
    padding: 0;
    margin: 0;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--black);
    background-color: #f5f5f5;
}

.overflowhidden,
body.overflowhidden {
    overflow: hidden;
}

.maxwidth1920{
    max-width: 1920px;
    margin: auto;
}


/* --- Bootsrep Overaid Css Start --- */
.container-lg.cl-custome {
    max-width: 1200px;
}

.container-lg.cl-1260-width {
    max-width: 1260px;
}
/* --- Bootsrep Overaid Css End --- */


/* --- Gradient background css start --- */
.gradient-background-bx {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--black);
}

.gb-noice{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../img/bg/noise.avif);
    background-size: 100px;
}

.gb-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-filter: blur(250px);
    filter: blur(250px);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.gb-shape {
    mix-blend-mode: lighten;
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
    position: absolute;
    border-radius: 100%;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.1, 0, 0.9, 1);
    animation-timing-function: cubic-bezier(0.1, 0, 0.9, 1);
}

.gb-shape.gb-s-1 {
    bottom: 0;
    left: 0;
    width: 700px;
    height: 700px;
    background: var(--lavender);
    mix-blend-mode: lighten;
    -webkit-transform: translate(-30%, 40%);
    transform: translate(-30%, 40%);
    -webkit-animation-name: gbsAnimation1;
    animation-name: gbsAnimation1;
}

.gb-shape.gb-s-2 {
    top: 0;
    right: 0;
    width: 600px;
    height: 600px;
    background: var(--aqua);
    -webkit-transform: translate(20%, -40%);
    transform: translate(20%, -40%);
    -webkit-animation-name: gbsAnimation2;
    animation-name: gbsAnimation2;
}

@-webkit-keyframes ggbsAnimation1 {
    0% {
        -webkit-transform: translate(-30%, 40%) rotate(-20deg);
        transform: translate(-30%, 40%) rotate(-20deg);
    }

    25% {
        -webkit-transform: translate(0%, 20%) skew(-15deg, -15deg) rotate(80deg);
        transform: translate(0%, 20%) skew(-15deg, -15deg) rotate(80deg);
    }

    50% {
        -webkit-transform: translate(30%, -10%) rotate(180deg);
        transform: translate(30%, -10%) rotate(180deg);
    }

    75% {
        -webkit-transform: translate(-30%, 40%) skew(15deg, 15deg) rotate(240deg);
        transform: translate(-30%, 40%) skew(15deg, 15deg) rotate(240deg);
    }

    100% {
        -webkit-transform: translate(-30%, 40%) rotate(-20deg);
        transform: translate(-30%, 40%) rotate(-20deg);
    }
}

@keyframes gbsAnimation1 {
    0% {
        -webkit-transform: translate(-30%, 40%) rotate(-20deg);
        transform: translate(-30%, 40%) rotate(-20deg);
    }

    25% {
        -webkit-transform: translate(0%, 20%) skew(-15deg, -15deg) rotate(80deg);
        transform: translate(0%, 20%) skew(-15deg, -15deg) rotate(80deg);
    }

    50% {
        -webkit-transform: translate(30%, -10%) rotate(180deg);
        transform: translate(30%, -10%) rotate(180deg);
    }

    75% {
        -webkit-transform: translate(-30%, 40%) skew(15deg, 15deg) rotate(240deg);
        transform: translate(-30%, 40%) skew(15deg, 15deg) rotate(240deg);
    }

    100% {
        -webkit-transform: translate(-30%, 40%) rotate(-20deg);
        transform: translate(-30%, 40%) rotate(-20deg);
    }
}

@-webkit-keyframes gbsAnimation2 {
    0% {
        -webkit-transform: translate(20%, -40%) rotate(-20deg);
        transform: translate(20%, -40%) rotate(-20deg);
    }

    20% {
        -webkit-transform: translate(0%, 0%) skew(-15deg, -15deg) rotate(80deg);
        transform: translate(0%, 0%) skew(-15deg, -15deg) rotate(80deg);
    }

    40% {
        -webkit-transform: translate(-40%, 50%) rotate(180deg);
        transform: translate(-40%, 50%) rotate(180deg);
    }

    60% {
        -webkit-transform: translate(-20%, -20%) skew(15deg, 15deg) rotate(80deg);
        transform: translate(-20%, -20%) skew(15deg, 15deg) rotate(80deg);
    }

    80% {
        -webkit-transform: translate(10%, -30%) rotate(180deg);
        transform: translate(10%, -30%) rotate(180deg);
    }

    100% {
        -webkit-transform: translate(20%, -40%) rotate(340deg);
        transform: translate(20%, -40%) rotate(340deg);
    }
}

@keyframes gbsAnimation2 {
    0% {
        -webkit-transform: translate(20%, -40%) rotate(-20deg);
        transform: translate(20%, -40%) rotate(-20deg);
    }

    20% {
        -webkit-transform: translate(0%, 0%) skew(-15deg, -15deg) rotate(80deg);
        transform: translate(0%, 0%) skew(-15deg, -15deg) rotate(80deg);
    }

    40% {
        -webkit-transform: translate(-40%, 50%) rotate(180deg);
        transform: translate(-40%, 50%) rotate(180deg);
    }

    60% {
        -webkit-transform: translate(-20%, -20%) skew(15deg, 15deg) rotate(80deg);
        transform: translate(-20%, -20%) skew(15deg, 15deg) rotate(80deg);
    }

    80% {
        -webkit-transform: translate(10%, -30%) rotate(180deg);
        transform: translate(10%, -30%) rotate(180deg);
    }

    100% {
        -webkit-transform: translate(20%, -40%) rotate(340deg);
        transform: translate(20%, -40%) rotate(340deg);
    }
}
/* --- Gradient background css end --- */


/* --- Common overlaydiv css start --- */
.overlaydiv {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    background: #0000008a;
    cursor: pointer;
    height: 100vh;
    margin: 0 !important;
    opacity: 0;
    position: fixed;
    top: 0;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    visibility: hidden;
    width: 100vw;
    z-index: 11;
}

.overlaydiv.active{
    opacity: 1;
    visibility: visible;
}
/* --- Common overlaydiv css end --- */


/* --- Close btn css start --- */
.close-icon{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
    background: transparent;
    -webkit-border-radius: 100%;
       -moz-border-radius: 100%;
            border-radius: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    color: var(--black);
    padding: 4px;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    z-index: 1;
}

.close-icon:hover{
    color: var(--black);
    background: #f3f4f6;
    -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
         -o-transform: rotate(360deg);
            transform: rotate(360deg);
}

.other-payment .close-icon{
    display: none;
}

.close-icon.wci{
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}
/* --- Close btn css end --- */


/* --- Common button css start --- */
.common-btn-item {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-border-radius: 54px;
    -moz-border-radius: 54px;
    border-radius: 54px;
    height: 36px;
    min-width: 100px;
    padding: 0 12px;
    font-size: 13px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--celeste);
    font-weight: 500;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

/* cbi fill button */
.common-btn-item.cbi-fill {
    color: var(--white);
    background-color: var(--black);
}

.common-btn-item.cbi-fill:hover {
    background-color: var(--black-800);
}

/* cbi outline button */
.common-btn-item.cbi-outline {
    color: var(--black);
    border: 1px solid var(--black);
}

.common-btn-item.cbi-outline:hover {
    color: var(--white);
    background-color: var(--black-800);
    border: 1px solid var(--black-800);
}


/* cbi none button */
.common-btn-item.cbi-none{
    color: var(--black);
}

.common-btn-item.cbi-none:hover {
    color: var(--white);
    background-color: var(--black-900);
}


/* cbi white button */
.common-btn-item.cbi-white {
    color: var(--black);
    background-color: var(--white);
}

.common-btn-item.cbi-white:hover {
    background-color: var(--white-800);
}

/* cbi button style */
.common-btn-item.cbi-big {
    font-size: 18px;
    padding: 12px 24px;
    height: 56px;
}

@media screen and (max-width: 767px) {
    .common-btn-item.cbi-big {
        font-size: 14px;
        padding: 10px 20px;
        height: 45px;
    }
}

/* @media only screen and (min-width: 768px) {
    .common-btn-item.cbi-fill:hover{
        background-color: var(--black-800);
    }

    .common-btn-item.cbi-outline:hover{
        color: var(--white);
        background-color: var(--black-800);
        border: 1px solid var(--black-800);
    }
}   */
/* --- Common button css end --- */


/* --- Social Share Icon & Social Icons start --- */
.social-icon{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 20px;
}

.social-icon{
    padding-bottom: 0;
}

.social-icon .si-item{
    padding: 0px 3px;
    text-align: center;
    cursor: pointer;
}

.social-icon .si-item .si-icon{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 40px;
    height: 40px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    overflow: hidden;
    margin: auto;
    font-size: 18px;
    color: var(--black-700);
    background-color: var(--black-100);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.social-icon .si-item .si-icon .fa{
    width: 20px;
    height: 20px;
    position: relative;
    top: 0px;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.social-icon .si-item:hover .si-icon{
    color: var(--white);
    background-color: var(--black);
}

/* --- Social Share Icon & Social Icons End --- */


/* --- Silke Btn Css Start --- */
.slick-arrow {
    width: 35px;
    height: 35px;
    border: none;
    background-color: transparent;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-size: 24px;
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -moz-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    border: 2px solid var(--black);
    opacity: 0.5;
    padding: 5px;
    overflow: hidden;
    color: transparent;
    z-index: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.slick-arrow::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    -webkit-background-size: 75% 75%;
    -moz-background-size: 75% 75%;
    -o-background-size: 75% 75%;
    background-size: 75% 75%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.slick-arrow.slick-next.slick-disabled,
.slick-arrow.slick-prev.slick-disabled {
    opacity: 0.2 !important;
    visibility: visible !important;
    pointer-events: none;
}

.slick-arrow.slick-prev {
    position: absolute;
    right: 40px;
    top: -40px;
}

.slick-arrow.slick-prev::after {
    background-position: 3px center;
    background-image: url("../img/icons/arrowright.svg");
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.slick-arrow.slick-next {
    position: absolute;
    right: 0px;
    top: -40px;
}

.slick-arrow.slick-next::after {
    background-position: 5px center;
    background-image: url("../img/icons/arrowright.svg");
}

.slick-arrow:hover {
    opacity: 1;
}

/* --- Slick style two --- */
.arrowtwo .slick-arrow.slick-prev {
    top: inherit;
    bottom: -40px;
    left: 50%;
    -webkit-transform: translateX(-webkit-calc(-50% - 20px));
    -moz-transform: translateX(-moz-calc(-50% - 20px));
    -ms-transform: translateX(calc(-50% - 20px));
    -o-transform: translateX(calc(-50% - 20px));
    transform: translateX(calc(-50% - 20px));
}

.arrowtwo .slick-arrow.slick-next {
    top: inherit;
    bottom: -40px;
    left: 50%;
    -webkit-transform: translateX(-webkit-calc(-50% + 20px));
    -moz-transform: translateX(-moz-calc(-50% + 20px));
    -ms-transform: translateX(calc(-50% + 20px));
    -o-transform: translateX(calc(-50% + 20px));
    transform: translateX(calc(-50% + 20px));
}

/* --- if width is full Slick Style Two --- */
.arrowfullwidth .slick-arrow.slick-prev {
    right: 55px;
}

.arrowfullwidth .slick-arrow.slick-next {
    right: 15px;
}
/* --- Silke btn css end --- */


/* --- Slick dots css start --- */
.slick-dots {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin: 5px 0px;
    padding: 0 8px;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 10px;
    z-index: 1;
}

.slick-dots li button {
    display: none;
}

.slick-dots li {
    list-style: none;
    width: 7px;
    height: 7px;
    background-color: var(--color4);
    margin: 2px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.slick-dots li.slick-active {
    background-color: var(--white);
    width: 14px;
}
/* --- Slick dots css end --- */


/* --- Lightgallery css start --- */
.lg-counter {
    color: white !important;
}

.lg-toolbar .lg-icon {
    color: rgba(255, 255, 255, 0.7) !important;
}

.lg-toolbar .lg-icon:hover {
    color: #ffffff !important;
}

.lg-download.lg-icon {
    display: none !important;
}

.lg-outer .lg-thumb-outer,
.lg-backdrop {
    background-color: #000000af !important;
    -webkit-backdrop-filter: blur(5px) !important;
    backdrop-filter: blur(5px) !important;
}

.lg-outer .lg-thumb {
    margin: auto;
    margin-bottom: -5px;
}

.lg-outer .lg-thumb-item {
    border-color: transparent;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
    border-color: var(--white) !important;
}

.lg-prev,
.lg-next {
    width: 40px !important;
    height: 40px !important;
    color: var(--white) !important;
    padding: 0 !important;
    padding-bottom: 2px !important;
    border: 2px solid var(--white) !important;
    -webkit-border-radius: 50px !important;
    -moz-border-radius: 50px !important;
    border-radius: 50px !important;
    background-color: transparent !important;
    -webkit-transition: all 0.3s ease-in-out !important;
    -o-transition: all 0.3s ease-in-out !important;
    -moz-transition: all 0.3s ease-in-out !important;
    transition: all 0.3s ease-in-out !important;
}

.lg-prev:hover,
.lg-next:hover {
    color: black !important;
    background-color: var(--white) !important;
}

.lg-prev:after {
    position: relative;
    top: 0px;
}

.lg-next:after {
    position: relative;
    top: 0px;
}

.lg-img-wrap {
    padding: 15px !important;
    padding-top: 60px !important;
    transition: padding-bottom 0.3s ease-in-out;
}

.lg-thumb-open .lg-img-wrap {
    padding-bottom: 110px !important;
}

@media screen and (max-width: 576px) {

    .lg-prev,
    .lg-next {
        display: none !important;
    }
}
/* --- Lightgallery css end --- */


/* --- Common Heading Bx Css Start --- */
.common-heading-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 5px;
}

.common-heading-bx .common-heading {
    font-size: 24px;
    color: var(--black);
    font-weight: 600;
}

.common-heading-bx .common-sub-heading {
    font-size: 16px;
    font-weight: 400;
    color: var(--black-900);
    max-width: 600px;
    width: 100%;
}

@media screen and (max-width: 768px) {
    /* .common-heading-bx .common-heading{
        font-size: 20px;
    } */

    .common-heading-bx .common-sub-heading {
        font-size: 14px;
    }
}
/* --- Common Heading Bx Css Start --- */


/* --- --- */
.common-text-layout {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    padding: 50px 0;
}

.common-text-layout .ctl-text {
    font-size: 22px;
    text-align: center;
    max-width: 600px;
}

.common-text-layout .ctl-heading {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
}

@media screen and (max-width: 992px) {
    .common-text-layout .ctl-text {
        font-size: 18px;
    }
}
/* --- --- */



/* --- Privacy Policy css Start --- */

.relative {
    position: relative;
}

.textlayout-main-bx {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding: 50px 0;
}

.breadcrumb-bx {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    width: 100%;
}

.breadcrumb-bx .breadcrumb-link {
    color: var(--black-200);
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
    transition: all .3s ease-in;
}

.breadcrumb-bx .breadcrumb-link.breadcrumb-active {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: var(--black-500);
    display: -webkit-box;
    font-weight: 500;
    overflow: hidden;
    pointer-events: none;
    text-decoration: none;
}

.breadcrumb-bx .breadcrumb-link {
    color: var( --black-700);
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
    transition: all .3s ease-in;
}

.breadcrumb-bx .breadcrumb-link:hover {
    color: var(--black);
    cursor: pointer;
}

.textlayout-main-bx .tlm-heading {
    color: var(--black);
    font-size: 32px;
    font-weight: 600;
    text-align: center;
}

.legal-content .main-sub-lable {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
}

.legal-content .main-lable, .legal-content .main-sub-lable {
    color: var(--black);
    margin-top: 30px;
    text-transform: capitalize;
}

.legal-content p, .legal-content ul li {
    color: var( --black-700);
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    margin: 10px 0 0;
    text-align: justify;
}

.legal-content ul.ul-dick {
    list-style: disc;
}

.legal-content ul {
    list-style: decimal;
    margin-left: 20px;
    margin-top: 10px;
    padding: 0 0 0 10px;
}

.legal-content p a, .legal-content ul li a {
    color: var(--black-800);
    text-decoration: underline;
}

.legal-content p a:hover, .legal-content ul a:hover {
    color: var(--black);
}

/* --- Privacy Policy css End --- */


/* --- Site map page css start --- */
.sitemap-heading{
    position: relative;
    color: var(--black);
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 10px;
    margin-top: 0px;
    margin-bottom: 20px;
}

.sitemap-heading::before,
.sitemap-heading::after{
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 20px;
    height: 1px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    background-color:  var(--black-200);
}

.sitemap-heading::before{
    left: inherit;
    right: 0;
    top: 100%;
    width: -webkit-calc(100% - 30px);
    width: -moz-calc(100% - 30px);
    width: calc(100% - 30px);
}

.sitemap-links-ul{
    list-style: none;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
    margin: 0 -10px;
}

.sitemap-links-ul .slu-li{
    flex: 0 0 25%;
    padding: 0 10px;
}

.sitemap-links-ul .slu-li .slul-link{
    display: flex;
    gap: 5px;
    position: relative;
    color: var(--black-700);
    font-size: 16px;
    font-weight: 500;
    margin-right: 0;
    cursor: pointer;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.sitemap-links-ul .slu-li .slul-link:hover {
    color: var(--black);
}

.sitemap-links-ul .slu-li .slul-link.active {
    color: var(--black);
}

.sitemap-links-ul .slu-li .slul-link svg{
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
    position: relative;
    top: 3.5px;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.sitemap-links-ul .slu-li .slul-link:hover svg{
    color: var(--black);
}

@media screen and (max-width:1100px) {
    .sitemap-links-ul .slu-li{
        flex: 0 0 33.33%;
    }
}

@media screen and (max-width:900px) {
    .sitemap-links-ul .slu-li .slul-link{
        font-size: 14px;
    }
}

@media screen and (max-width:768px) {
    .sitemap-links-ul .slu-li{
        flex: 0 0 50%;
    }
}

@media screen and (max-width:360px) {
    .sitemap-links-ul .slu-li{
        flex: 0 0 100%;
    }
}

/* --- Site map page css end --- */

/* --- About Us page css Start --- */

.about-maxw .common-heading-bx .common-heading {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
}

.about-maxw .common-heading-bx .common-sub-heading {
    max-width: 1200px;
    width: 100%;
    color: var(--black-700);
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
}

/* --- About Us page css end --- */
