.custom-header{
    position: sticky;
    top: 0;
    width: 100%;
    padding: 0 30px;
    background-color: var(--white);
    z-index: 11;
    border-bottom: 1px solid var(--black-100);
}

.custom-header .ch-inner{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    min-height: 70px;
    padding: 16px 0;
}

.custom-header .ch-inner .ch-right,
.custom-header .ch-inner .ch-left{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    line-height: inherit;
}


/* --- Logo css start --- */
.ch-logo{
    cursor: pointer;
}

.ch-logo img{
    width: 160px;
    display: block;
}
/* --- Logo css end --- */

/* --- Aside Menu Icon Start --- */
.menu-icon-bx {
    width: 26px;
    height: 26px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 26px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 26px;
            flex: 0 0 26px;
    display: none;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0px;
    cursor: pointer;
    margin-left: 15px;
}

.menu-icon-bx span {
    display: block;
    width: 100%;
    height: 2px;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    opacity: 0.6;
    background-color: var(--black);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

/* .menu-icon-bx span:nth-child(3) {
    width: 60%;
    margin-right: auto;
} */

.menu-icon-bx:hover span,
.menu-icon-bx.active span{
    opacity: 1;
}

.menu-icon-bx.active span:nth-child(1) {
    width: 50%;
    margin-left: auto;
}

.menu-icon-bx.active span:nth-child(2) {
    width: 90%;
}

.menu-icon-bx.active span:nth-child(3) {
    width: 50%;
    margin-right: auto;
}
/* --- Aside Menu Icon End --- */


/* --- Signinup Btn Bx Start --- */
.signinup-btn-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    gap: 6px;
}

.signinup-btn-bx .common-btn-item{
    font-size: 16px;
    padding: 8px 20px;
    height: auto;
    font-weight: 600;
}
/* --- Signinup btn bx end --- */



/* --- @media css start --- */

@media screen and (max-width:992px) {
    .menu-icon-bx{
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
    }
}

@media screen and (max-width:1024px) {

    .custom-header{
        padding: 0 15px;
    }
    
}

@media screen and (max-width:576px) {

    .custom-header .ch-logo img{
        width: 120px
    }

    .signinup-btn-bx .common-btn-item{
        padding: 5px 12px 5px;
        width: auto;
        min-width: auto;
        font-size: 14px;
    }

    .menu-icon-bx{
        margin-left: 10px;
    }

    .custom-header{
        height: auto;
    }
    
}

@media screen and (max-width:350px) {

    .custom-header .ch-logo img{
        width: 38px;
        height: 38px;
        transform: scale(1.5);
    }
    
}
/* --- @media css end --- */