.card-item{
    padding: 15px 0;
}

.card-item .card-i-img{
    width: 44px;
    height: 44px;
    margin-bottom: 24px;
}

.card-item .card-i-img *{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.card-item .card-i-heading{
    font-size: 24px;
    font-weight: 700;
}

.card-item .card-i-text{
    font-size: 1.125rem;
    padding-top: 1rem;
}