/* --- Common Field Update Css Start --- */

:root {
    --PhoneInputCountryFlag-height: 1em;
    --PhoneInputCountryFlag-aspectRatio: 1.5;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
    display: none;
}

::placeholder{
    font-size: 14px;
    color: var(--black-500);
    font-weight: 500;
    font-family: "Inter", sans-serif;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* --- Common Field Update Css End --- */


/* --- Extra Label Css Start --- */
.sgr-outer-label{
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
}
/* --- Extra Label Css End --- */


.signinup-group {
    position: relative;
    margin: 0px 0 18px;
}

/* --- Signinup Lable Start --- */
.signinup-group .group__label {
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 1;
    font-size: 14px;
    color: #708494;
    font-weight: 500;
    margin: 0;
    -webkit-transform: scale(1) translateY(11px);
       -moz-transform: scale(1) translateY(11px);
        -ms-transform: scale(1) translateY(11px);
         -o-transform: scale(1) translateY(11px);
            transform: scale(1) translateY(11px);
    -webkit-transform-origin: left;
       -moz-transform-origin: left;
        -ms-transform-origin: left;
         -o-transform-origin: left;
            transform-origin: left;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 0 0px;
    pointer-events: none;
}

.signinup-group .group__label span{
    font-size: 14px;
    margin-left: 3px;
    color: #608ed7;
}
/* --- Signinup Lable End --- */


.signinup-group select,
.signinup-group textarea,
.signinup-group input {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    display: block;
    color: var(--black);
    border: 0;
    font-size: 14px;
    border: 1px solid var(--black-100);
    background-color: var(--white);
    width: 100%;
    line-height: 20px;
    outline: none;
    padding: 10px 12px;
    -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
            border-radius: 8px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.gopageinput.signinup-group input{
    height: auto;
    padding: 4px 3px;
    width: 46px;
    text-align: center;
}

.signinup-group select[disabled],
.signinup-group textarea[disabled],
.signinup-group input[disabled] {
    cursor: not-allowed;
    pointer-events: none;
}

.signinup-group textarea{
    min-height: 80px;
}

.signinup-group.sg-150-textarea textarea{
    min-height: 150px;
}


/* .signinup-group:not(.error-group) select:hover,
.signinup-group:not(.error-group) textarea:hover,
.signinup-group:not(.error-group) input:hover{
    border-color: var(--black);
    box-shadow: 0 0 0.1px 1px var(--black);
} */

/* .signinup-group textarea:not(:placeholder-shown), */
.signinup-group textarea:focus,
/* .signinup-group select:not(:placeholder-shown), */
.signinup-group select:focus,
/* .signinup-group input:not(:placeholder-shown), */
.signinup-group input:focus {
    border-color: var(--aquamarine);
    box-shadow: 0 0 0px 1px var(--aquamarine);
}



.signinup-group.sgl-icon select,
.signinup-group.sgl-icon input{
    padding-left: 44px;
}

.signinup-group.sgr-icon select,
.signinup-group.sgr-icon input{
    padding-right: 44px;
}

.signinup-group .group_right_icon,
.signinup-group .group_left_icon{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    font-size: 14px;
    color: var(--black);
    font-weight: 500;
}

.signinup-group .group_left_icon{
    position: absolute;
    left: 0;
    top: 0;
}

.signinup-group .group_right_icon{
    position: absolute;
    right: 0;
    top: 0;
}

.signinup-group .group_right_icon svg,
.signinup-group .group_left_icon svg{
    height: 22px;
    width: 22px;
    opacity: .5;
    transition: all .3s ease-in-out;
}

.signinup-group.sgl-opacity-1 svg{
    opacity: 1;
}

.signinup-group .group_right_icon.gri-select-icon{
    pointer-events: none;
}

.signinup-group .group_right_icon.gri-select-icon svg{
    height: 20px;
    width: 20px;
}

.signinup-group:hover .group_right_icon.gri-select-icon svg,
.signinup-group .group_right_icon:hover svg,
.signinup-group .group_left_icon:hover svg{
    opacity: 1;
}


/* ---
Error Input color css
--- */

.signinup-group.error-group textarea,
.signinup-group.error-group select,
.signinup-group.error-group input{
    border-color: var(--error) !important;
    -webkit-box-shadow: inset 0 0 0 100px var(--white), 0 0 0px 1px var(--error) !important;
    box-shadow: inset 0 0 0 100px var(--white), 0 0 0px 1px var(--error) !important;
}


/* Form Error Msg */
.form-error-msg{
    display: block;
    text-align: right;
    color: var(--error);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: -5px;
    margin-top: 8px;
    letter-spacing: 0.5px;
}

.form-error-msg .fa{
    margin-right: 5px;
}

.form-error-msg::before{
    display: none;
    content: "\f071";
    font: normal normal normal 14px/1 FontAwesome;
    margin-right: 5px;
    font-size: 10px;
}


/* --- --- */
.input-note{
    font-size: 12px;
    margin-top: 5px;
    color: var(--black);
    /* font-weight: 500; */
}

.input-note-2{
    font-weight: 600;
    color: var(--black-700);
    font-size: 14px;
    margin-top: 10px;
}

.input-note-2 svg{
    width: 24px;
    height: 24px;
    position: relative;
    top: -1.5px;
}


/* --- Remember Input Checkbox --- */
.signinup-group-checkmark input{
    display: none;
}

.signinup-group-checkmark{
    padding-left: 24px;
    margin-bottom: 13px;
    position: relative;
    z-index: 3;
}

.signinup-group-checkmark label{
    cursor: pointer;
    font-size: 14px;
    color: var(--black-500);
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}


.signinup-group-checkmark:not(.sgc-ci-hover-off) label:hover{
    color: var(--black);
}

.signinup-group-checkmark label a{
    text-decoration: underline;
}

.signinup-group-checkmark label a:hover{
    color: var(--black);
}

.signinup-group-checkmark label .checkmark-icon{
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 1.5px;
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 4px;
    background-position: center;
    background-color: var(--white);
    border: 1px solid var(--black-100);
    box-shadow: 0 0 0px 4px transparent inset;
    background-repeat: no-repeat;
    background-position: center;
    background-size: calc(100% - 2px);
    transition: 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.signinup-group-checkmark.sgc-ci-18px label .checkmark-icon{
    width: 18px;
    height: 18px;
    top: 2px;
}

.signinup-group-checkmark label:hover .checkmark-icon{
    border-color: var(--black);
    /* box-shadow: 0 0 0px 1px var(--black); */
}

.signinup-group-checkmark input:checked + label{
    color: var(--black);
}

.signinup-group-checkmark input:checked + label .checkmark-icon{
    background-color: var(--black);
    border-color: var(--black);
    box-shadow: none;
    background-image: url(../img/icons/check.svg);
}


.signinup-group .sg-list-bx{
    border-radius: 8px;
    background-color: var(--light-s-gray);
    padding: 8px;
    display: flex;
    align-items: center;
    max-width: fit-content;
    width: 100%;
    gap: 8px;
    overflow: hidden;
    overflow-x: auto;
}

.signinup-group .sg-list-bx .sg-list-item{
    position: relative;
    color: var(--black-700);
    font-size: 14px;
    font-weight: 600;
    padding: 8px 8px;
    /* transition: all 0.3s ease-in-out; */
    cursor: pointer;
    border-radius: 8px;
}

.signinup-group .sg-list-bx .sg-list-item img{
    background-color: var(--white);
    border-radius: 8px;
    width: 110px;
    height: 60px;
    object-position: center;
    object-fit: contain;
}

.signinup-group .sg-list-bx .sg-list-item:hover{
    color: var(--black);
}

.signinup-group .sg-list-bx .sg-list-item.active{
    color:var(--white);
    background-image: linear-gradient(45deg, #000, #000);
}

/*---phone-number-input start--*/

.sg-country-code .PhoneInputCountry {
    height: 43px;
    left: 10px;
    position: absolute;
    top: 0;
}

.PhoneInputCountryIcon {
    height: 1em;
    height: var(--PhoneInputCountryFlag-height);
    width: 1.5em;
    width: calc(var(--PhoneInputCountryFlag-height)* var(--PhoneInputCountryFlag-aspectRatio));
}

.PhoneInputCountrySelect {
    border: 0;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
.PhoneInputCountryIconImg {
    display: block;
    height: 100%;
    width: 100%;
}

.sg-country-code .PhoneInputCountrySelectArrow {
    border-width: 0 1.5px 1.5px 0;
    left: 3px;
    padding: 3px;
    position: relative;
    top: -2px;
}

.sg-country-code input {
    padding-left: 58px;
}

.PhoneInputInput {
    flex: 1 1;
    min-width: 0;
}

.PhoneInputCountrySelectArrow {
    border-bottom-width: 1px;
    border-color: currentColor;
    border-left-width: 0;
    border-right-width: 1px;
    border-style: solid;
    border-top-width: 0;
    content: "";
    display: block;
    height: .3em;
    margin-left: .35em;
    opacity: .45;
    transform: rotate(45deg);
    width: .3em;
}

.PhoneInputCountry {
    align-items: center;
    align-self: stretch;
    display: flex;
    margin-right: .35em;
    position: relative;
}

/*---phone-number-input End---*/

/* --- Input Autofill Css --- */
.signinup-group:not(.error-group) select:-webkit-autofill,
.signinup-group:not(.error-group) select:-webkit-autofill:hover,
.signinup-group:not(.error-group) select:-webkit-autofill:focus,
.signinup-group:not(.error-group) select:-webkit-autofill:active,
.signinup-group:not(.error-group) input:-webkit-autofill,
.signinup-group:not(.error-group) input:-webkit-autofill:hover,
.signinup-group:not(.error-group) input:-webkit-autofill:focus,
.signinup-group:not(.error-group) input:-webkit-autofill:active {
    font-weight: 400 !important;
    font-size: 14px !important;
    caret-color: var(--black) !important;
    color: var(--black) !important;
    -webkit-text-fill-color: var(--black) !important;
    border-color: var(--aquamarine);
    -webkit-box-shadow: inset 0 0 0 100px var(--white), 0 0 0px 1px var(--aquamarine) !important;
            box-shadow: inset 0 0 0 100px var(--white), 0 0 0px 1px var(--aquamarine) !important;
}


/* --- login & submit btn --- */
.common-submit-btn,
.lsb-loader-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 9px 15px;
    -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
            border-radius: 8px;
    width: 100%;
    font-weight: 500;
    color: var(--white);
    font-size: 16px;
    margin: 0 auto 13px;
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: var(--black-900);
    transition: all 0.3s ease-in-out;
    max-width: 450px;
    width: 100%;
    letter-spacing: 0.5px;
}

.common-submit-btn.csb-width-fit{
    max-width: fit-content;
    width: 100%;
    padding: 9px 25px;
}

.common-submit-btn[disabled]{
    background-color: var(--black-700);
}

.common-submit-btn:hover{
    color: var(--white);
    background-color: var(--black);
}


/* --- lsb loader btn --- */
.lsb-loader-btn svg,
.lsb-loader-btn img{
    pointer-events: none;
    width: 18px;
    height: 18px;
    margin-right: 7px;
    position: relative;
    margin-right: 8px;
    -webkit-animation: rotation-btn 1s infinite linear;
       -moz-animation: rotation-btn 1s infinite linear;
         -o-animation: rotation-btn 1s infinite linear;
            animation: rotation-btn 1s infinite linear;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@-webkit-keyframes rotation-btn {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-moz-keyframes rotation-btn {
    from {
        -moz-transform: rotate(0deg);
             transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
             transform: rotate(360deg);
    }
}

@-o-keyframes rotation-btn {
    from {
        -o-transform: rotate(0deg);
           transform: rotate(0deg);
    }

    to {
        -o-transform: rotate(360deg);
           transform: rotate(360deg);
    }
}

@keyframes rotation-btn {
    from {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}


/* --- Common File Upload Css Start --- */
.fileupload{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 10px;
    width: 100%;
    min-height: 100px;
    border-radius: 10px;
    background-color: var(--light-s-gray);
    border: 1px dashed #d1d5db;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.fileupload .feud-img{
    width: 50px;
    height: 50px;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
}

.fileupload svg{
    width: 50px;
    height: 50px;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
}

.fileupload .feud-lable{
    color: var(--black-700);
    font-weight: 500;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 5px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.fileupload .feud-sub-lable{
    font-weight: 500;
    color: var(--black);
    font-size: 12px;
}

.fileupload .feud-lable span{
    color: var(--black);
    text-decoration: underline;
}

.fileupload.dragover,
.fileupload:hover{
    border-style: solid;
    border-color: var(--black);
    box-shadow: 0 0 0px 1px var(--black);
}

.fileupload.dragover *{
    pointer-events: none
}

.fileupload.dragover .feud-img,
.fileupload.dragover svg,
.fileupload:hover svg,
.fileupload:hover .feud-img{
    opacity: 1;
}

.fileupload:hover .feud-lable{
    color: var(--black);
}
/* --- Common File Upload Css End --- */


/* --- Common File Upload Item Css Start --- */
.upload-document-item{
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #d1d5db;
}

.upload-document-item .udi-img-bx{
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    background-color: var(--light-s-gray);
    border-radius: 8px;
    overflow: hidden;
}

.upload-document-item .udi-img-bx img{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.upload-document-item .udi-data-bx{
    flex: auto;
}

.upload-document-item .udi-data-bx .udi-data-name{
    font-size: 14px;
    font-weight: 600;
    color: var(--black);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
}

.upload-document-item .udi-data-bx .udi-data-type-size{
    font-weight: 600;
    color: var(--black-700);
    font-size: 12px;
    margin-top: 3px;
}

.upload-document-item .udi-icon-bx{
    cursor: pointer;
    position: relative;
    color: var(--celeste-800);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    flex: 0 0 46px;
    padding: 10px;
    border-radius: 50%;
    transition: all 0.1s ease-in-out;
}

.upload-document-item .udi-icon-bx::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color:var(--light-s-gray);
    transform: scale(0);
    transform-origin: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s ease-in-out;
}

.upload-document-item .udi-icon-bx:active,
.upload-document-item .udi-icon-bx:hover{
    color: var(--black);
}

.upload-document-item .udi-icon-bx:hover::after{
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.upload-document-item .udi-icon-bx svg{
    position: relative;
    z-index: 1;
}

.upload-document-item .udi-icon-bx.udi-delete:active,
.upload-document-item .udi-icon-bx.udi-delete:hover{
    color: var(--error);
}
/* --- Common File Upload Item Css End --- */

.n-challenge {
    display: flex;
}