/* --- Contact Page Css Start --- */
.contact-info{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
    padding: 15px 10px;
    margin: 5px 0 !important;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--black-100);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    overflow: hidden;
  }
  
  .contact-info::after{
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    inset: 0;
    z-index: -1;
    opacity: 0;
    background-color: var(--black-50);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }
  
  .contact-info:hover::after {
    opacity: 1;
  }
  
  .contact-info .contact-icon {
    padding: 2px;
    width: 40px;
    height: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 40px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 40px;
            flex: 0 0 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    opacity: 0.8;
  }
  
  .contact-info:hover .contact-icon {
    opacity: 1;
  }
  
  .contact-info .contact-icon img {
    width: 100%;
  }
  
  .contact-info .contact-text h6 {
    color: var(--black-500);
    font-weight: 600;
    font-size: 14px;
    line-height: 1.73;
    overflow: hidden;
    margin: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .contact-info .contact-text p {
    color: var(--black-700);
    font-size: 16px;
    margin: 0;
    font-weight: 400;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 3px 0px;
  }
  
  .contact-info:hover .contact-text p {
    color: var(--black);
    text-decoration: underline;
  }
  
  .common-btn-item-contact.cbi-outline:hover{
    color: var(--black-900);
    background-color: var(--black-500);
    border-color: var(--black-500);
  }
  
  .common-btn-item-contact.cbi-outline{
    color: var(--black);
    border-color: var(--white-700);
    }

.form-bx {
    padding: 28px 15px 15px 15px;
    background: var(--white);
    border-radius: 25px;
}
  
  /* --- Contact Page Css End --- */
  
  