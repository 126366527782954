/* --- side-menu-bx css Start --- */
.side-menu-bx{
    width: 320px;
    position: fixed;
    top: 0px;
    right: 0;
    height: 100%;
    z-index: 1999;
    background-color: #fcfcfc;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translateX(100%);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    -webkit-box-shadow: 0px 0px 20px -10px var(--electricblue-500);
       -moz-box-shadow: 0px 0px 20px -10px var(--electricblue-500);
            box-shadow: 0px 0px 20px -10px var(--electricblue-500);
}

.side-menu-bx.active {
    right: 0;
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
}

.smb-logo-bx {
    background-color: var(--white);
    border-bottom: 1px solid var(--black-100);
    height: 74px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 15px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 11;
}

.smb-logo-bx .smb-logo-link {
    display: block;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.1px;
}

.smb-logo-bx .smb-logo-link img {
    width: 160px;
    /* height: 60px; */
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(20px);
       -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
         -o-transform: translateY(20px);
            transform: translateY(20px);
    -webkit-transition: 0.3s all 0.5s ease;
    -o-transition: 0.3s all 0.5s ease;
    -moz-transition: 0.3s all 0.5s ease;
    transition: 0.3s all 0.5s ease;
}

.side-menu-bx.active .smb-logo-bx .smb-logo-link img {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
}

.smb-menu-bx {
    height: -webkit-calc(100% - 74px - 65px);
    height: -moz-calc(100% - 74px - 65px);
    height: calc(100% - 74px - 65px);
    max-height: -webkit-calc(100% - 74px - 65px);
    max-height: -moz-calc(100% - 74px - 65px);
    max-height: calc(100% - 74px - 65px);
    position: absolute;
    width: 100%;
    top: 0;
    margin-top: 74px;
    overflow: auto;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.smb-menu-bx::-webkit-scrollbar{
    display: none;
}

.smb-menu-bx.smb-hide{
    opacity: 0.1;
    -webkit-transform: scale(0.9);
       -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
         -o-transform: scale(0.9);
            transform: scale(0.9);
}

.smb-menu-bx.smb-seconde-bx{
    left: 330px;
}

.smb-menu-bx.smb-seconde-bx.smb-se-show{
    left: 0;
}

.smb-menu-bx ul {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0 0px;
    list-style: none;
    padding-bottom: 50px;
}

.smb-menu-bx ul .smb-nav-lable{
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: var(--black);
    display: block;
    padding: 10px 0px;
    margin: 0 15px;
}

.smb-menu-bx ul .smb-nav-lable::before,
.smb-menu-bx ul .smb-nav-lable::after{
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 20px;
    height: 1px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    background-color:  var(--black-700);
}

.smb-menu-bx ul .smb-nav-lable::before{
    left: inherit;
    right: 0;
    top: 100%;
    width: -webkit-calc(100% - 30px);
    width: -moz-calc(100% - 30px);
    width: calc(100% - 30px);
    background-color:  var(--black-700);
}

.smb-menu-bx ul li {
    margin: 0px 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(20px);
       -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
         -o-transform: translateY(20px);
            transform: translateY(20px);
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    -moz-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
}

.side-menu-bx.active .smb-menu-bx ul li {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
     -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
         transform: translateY(0px);
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(1) {
    -webkit-transition-delay: 0.6s;
       -moz-transition-delay: 0.6s;
         -o-transition-delay: 0.6s;
            transition-delay: 0.6s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(2) {
    -webkit-transition-delay: 0.7s;
       -moz-transition-delay: 0.7s;
         -o-transition-delay: 0.7s;
            transition-delay: 0.7s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(3) {
    -webkit-transition-delay: 0.8s;
       -moz-transition-delay: 0.8s;
         -o-transition-delay: 0.8s;
            transition-delay: 0.8s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(4) {
    -webkit-transition-delay: 0.9s;
       -moz-transition-delay: 0.9s;
         -o-transition-delay: 0.9s;
            transition-delay: 0.9s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(5) {
    -webkit-transition-delay: 1.0s;
       -moz-transition-delay: 1.0s;
         -o-transition-delay: 1.0s;
            transition-delay: 1.0s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(6) {
    -webkit-transition-delay: 1.1s;
       -moz-transition-delay: 1.1s;
         -o-transition-delay: 1.1s;
            transition-delay: 1.1s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(7) {
    -webkit-transition-delay: 1.2s;
       -moz-transition-delay: 1.2s;
         -o-transition-delay: 1.2s;
            transition-delay: 1.2s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(8) {
    -webkit-transition-delay: 1.3s;
       -moz-transition-delay: 1.3s;
         -o-transition-delay: 1.3s;
            transition-delay: 1.3s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(9) {
    -webkit-transition-delay: 1.4s;
       -moz-transition-delay: 1.4s;
         -o-transition-delay: 1.4s;
            transition-delay: 1.4s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(10) {
    -webkit-transition-delay: 1.5s;
       -moz-transition-delay: 1.5s;
         -o-transition-delay: 1.5s;
            transition-delay: 1.5s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(11) {
    -webkit-transition-delay: 1.6s;
       -moz-transition-delay: 1.6s;
         -o-transition-delay: 1.6s;
            transition-delay: 1.6s;
}


.smb-menu-bx ul li .smb-link {
    padding: 0px 20px 0px 20px;
    margin-top: 2px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    text-decoration: none;
    color: var(--black-600);
    position: relative;
    height: 45px;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.smb-menu-bx ul li .smb-link::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    width: -webkit-calc(100% - 30px);
    width: -moz-calc(100% - 30px);
    width: calc(100% - 30px);
    height: 1px;
    -webkit-transform: translateX(-50%);
       -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
         -o-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: var(--black-100);
}

.smb-menu-bx ul li:last-child .smb-link:after {
    content: none;
}

.smb-menu-bx ul li .smb-link svg{
    position: relative;
    top: 1px;
    width: 23px;
    height: 23px;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
    margin-right: 8px;
}

.smb-menu-bx ul li .smb-link:hover {
    color: var(--black);
}

.smb-menu-bx ul li .smb-link.active {
    opacity: 1;
    color: var(--black);
}

/* --- --- */
.smb-signinup-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 15px 15px 0;
    gap: 10px;
}

.smb-signinup-btn > .common-btn-item{
    width: 100%;
    font-size: 16px;
    padding: 8px 20px;
    height: auto;
    font-weight: 600;
}

/* --- --- */
.smb-footer{
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 15px 10px 10px;
    background-color: var(--white);
    border-top: 1px solid var(--black-100);
    width: 100%;
    margin: 0;
}

/* @media screen and (max-width:400px) {
    .side-menu-bx{
        max-width: 100%;
        width: 100%;
        right: -400px;
    }
} */