.detail-main-section{
    background-color: var(--black);
}

.detail-main-section .dms-inner{
    display: flex;
    gap: 40px;
}

.detail-main-section .dms-inner .dms-i-left{
    flex-shrink: 0;
    flex-basis: 320px;
    color: var(--white);
}

.detail-main-section .dms-inner .dms-i-left .dms-name{
    font-size: 36px;
    font-weight: 600;
    line-height: normal;
}

.detail-main-section .dms-inner .dms-i-left .dms-price{
    font-size: 14px;
    padding-top: 8px;
}

.detail-main-section .dms-inner .dms-i-left .dms-about{
    font-size: 16px;
    padding-top: 24px;
}

.detail-main-section .dms-inner .dms-i-left .dms-separator{
    width: 100%;
    height: 1px;
    background-color: var(--white-300);
    margin: 24px 0;
}

.detail-main-section .dms-inner .dms-i-left .dms-tag-bx .dtb-top{
    font-size: 12px;
    color: var(--white-700);
    font-weight: 600;
}

.detail-main-section .dms-inner .dms-i-left .dms-tag-bx .dtb-other{
    font-size: 14px;
    color: var(--white);
    font-weight:  400;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
}

.detail-main-section .dms-inner .dms-i-left .dms-tag-bx .dtb-other svg{
    position: relative;
    top: -1px;
    width: 16px;
    height: 16px;
}

.detail-main-section .dms-inner .dms-i-left .cbi-dms-btn{
    width: 100%;
    font-size: 18px;
    padding: 12px 24px;
    height: 50px;
    margin-top: 24px;
}



.detail-main-section .dms-inner .dms-i-right{
    flex-basis: 100%;
}

.detail-main-section .dms-inner .dms-i-right .dms-theme-item{
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid  var(--white-100);
}

.detail-main-section .dms-inner .dms-i-right .dms-theme-item .tib-img-bx{
    position: static;
    padding-top: inherit;
    height: 520px;
    overflow: hidden;
    overflow-y: auto;
    border: none;
    border-radius: inherit;
}

.detail-main-section .dms-inner .dms-i-right .dms-theme-item .tib-img-bx .tib-main-img{
    position: static;
}

.detail-main-section .dms-inner .dms-i-right .dms-theme-item .tib-responsive-img {
    border-radius: 25px;
    padding: 10px;
    padding-top: 36px;
    width: 200px;
    right: 30px;
}


/* --- theme-gallery --- */
.theme-gallery-item.tgi-slider{
    padding: 10px;
}

@media screen and (max-width:576px) {
    .theme-gallery-item.tgi-slider {
        padding: 10px 5px;
    }
}

.theme-gallery-item .tgi-item{
    cursor: pointer;
    display: inline-block;
    position: relative;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid var(--black-100);
}

.theme-gallery-item .tgi-item img{
    width: 100%;
    transition: all 0.3s ease-in-out;
}

.theme-gallery-item .tgi-item:hover img{
    transform: scale(1.1);
}

.theme-gallery-item .tgi-item .tgi-zoom-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: var(--white);
    background-color: var(--black-300);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.theme-gallery-item .tgi-item .tgi-zoom-icon svg{
    width: 60px;
    height: 60px;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.9);
    transition: all 0.3s ease-in-out;
}

.theme-gallery-item .tgi-item:hover .tgi-zoom-icon svg,
.theme-gallery-item .tgi-item:hover .tgi-zoom-icon{
    opacity: 1;
    visibility: visible;
}

.theme-gallery-item .tgi-item:hover .tgi-zoom-icon svg{
    transform: scale(1);
}


/* --- theme-gallery --- */

@media screen and (max-width: 768px) {
    .detail-main-section .dms-inner{
        flex-direction: column;
    }

    .detail-main-section .dms-inner .dms-i-right .dms-theme-item .tib-img-bx{
        height: 400px;
    }

    .detail-main-section .dms-inner .dms-i-right .dms-theme-item .tib-responsive-img{
        width: 160px;
    }
}

@media screen and (max-width: 576px) {
    .detail-main-section .dms-inner .dms-i-right .dms-theme-item .tib-img-bx{
        overflow: hidden;
    }

    .detail-main-section .dms-inner .dms-i-right .dms-theme-item .tib-responsive-img{
        width: 140px;
    }
    
}