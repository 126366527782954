.main-sction {
    background-image: url("../img/bg/wave.svg");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 15px;
    padding-top: 32px;
    color: var(--white);
    text-align: center;
}

.main-sction .ms-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.main-sction .ms-logo {
    filter: invert(1);
    width: 160px;
    margin-bottom: 20px;
}

.main-sction .ms-logo img {
    width: 100%;
}

.main-sction .ms-heading {
    font-size: 56px;
    font-weight: 700;
    text-align: center;
    line-height: 68px;
}

.main-sction .ms-sub-heading {
    font-size: 22px;
}

.main-sction .ms-form-bx {
    max-width: 416px;
    width: 100%;
    position: relative;
}

.main-sction .ms-form-bx input {
    width: 100%;
    height: 60px;
    font-weight: 400;
    border-radius: 50px;
    border: 1px solid transparent;
    outline: none;
    padding: 28px 116px 10px 20px;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
}

.main-sction .ms-form-bx label {
    position: absolute;
    left: 20px;
    top: 16px;
    color: var(--black-900);
    font-size: 18px;
    font-weight: 400;
    pointer-events: none;
    transform-origin: left;
    transition: all 0.3s ease-in-out;
}

.main-sction .ms-form-bx input:focus,
.main-sction .ms-form-bx input:not(:placeholder-shown) {
    border-color: var(--lavender);
}

.main-sction .ms-form-bx input:not(:placeholder-shown)~label,
.main-sction .ms-form-bx input:focus~label {
    transform: scale(0.7) translateY(-18px);
    color: var(--black-700);
}

.main-sction .ms-form-bx .cbi-ms-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    height: calc(60px - 10px);
    font-size: 16px;
    letter-spacing: 0.8px;
}

.main-sction .ms-sub-small {
    font-size: 14px;
}

.main-sction .ms-img {
    max-width: 576px;
    width: 100%;
    padding-top: 32px;
}

@media screen and (max-width: 1200px) {
    .main-sction .ms-heading {
        font-size: 48px;
        line-height: 56px;
    }
}

@media screen and (max-width: 900px) {
    .main-sction .ms-form-bx input {
        padding: 28px 20px 10px 20px;
    }

    .main-sction .ms-form-bx .cbi-ms-btn {
        position: relative;
        right: initial;
        top: initial;
        height: 56px;
        width: 100%;
        margin-top: 15px;
    }

    .main-sction .ms-sub-heading {
        font-size: 18px;
    }

    .main-sction .ms-heading {
        font-size: 40px;
        line-height: 48px;
    }
}

@media screen and (max-width: 576px) {
    .main-sction .ms-heading {
        font-size: 36px;
        line-height: 42px;
    }
}

@media screen and (max-width: 420px) {
    .main-sction .ms-heading {
        font-size: 28px;
        line-height: 36px;
    }
}

/* --- Home page css end --- */

/* --- Client bs css start --- */
.clients-outer {
    border-top: 1px solid var(--black-300);
    border-bottom: 1px solid var(--black-300);
    padding: 32px 0;
}

.clients-bx {
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 20px;
    row-gap: 30px;
    max-width: 930px;
    margin: auto;
}

.client-item {
    max-width: 210px;
    margin: auto;
}

.client-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

@media screen and (max-width: 640px) {
    .clients-bx {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 20px;
        row-gap: 30px;
        max-width: 930px;
        margin: auto;
    }
}

/* --- Client bs css end --- */

/* --- pbl-item start --- */

.page-bottom-links {
    display: flex;
    justify-content: flex-end;
    column-gap: 100px;
    padding: 32px 0;
}

.pbl-item {
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
}

.pbl-item:hover {
    color: var(--black);
    text-decoration: underline;
}

@media screen and (max-width: 1200px) {
    .page-bottom-links {
        column-gap: 15px;
    }
}

/* --- pbl-item end --- */