:root{
    --white: #ffffff;
    --white-900: rgba(255, 255, 255, 0.9);
    --white-800: rgba(255, 255, 255, 0.8);
    --white-700: rgba(255, 255, 255, 0.7);
    --white-600: rgba(255, 255, 255, 0.6);
    --white-500: rgba(255, 255, 255, 0.5);
    --white-400: rgba(255, 255, 255, 0.4);
    --white-300: rgba(255, 255, 255, 0.3);
    --white-200: rgba(255, 255, 255, 0.2);
    --white-100: rgba(255, 255, 255, 0.1);

    --black: #000000;
    --black-900: rgba(0, 0, 0, 0.9);
    --black-800: rgba(0, 0, 0, 0.8);
    --black-700: rgba(0, 0, 0, 0.7);
    --black-600: rgba(0, 0, 0, 0.6);
    --black-500: rgba(0, 0, 0, 0.5);
    --black-400: rgba(0, 0, 0, 0.4);
    --black-300: rgba(0, 0, 0, 0.3);
    --black-200: rgba(0, 0, 0, 0.2);
    --black-100: rgba(0, 0, 0, 0.1);
    --black-50: rgba(0, 0, 0, 0.050);

    --mint-Green: #4bfe85;

    --aquamarine: #5fd5b6;

    --lavender: #8e7bff;

    --aqua: #44f2eb;

    --error: #fe6b6b;
    --error-400: rgba(254, 107, 107, 0.4);
    --error-300: rgba(254, 107, 107, 0.3);
    --error-100: rgba(254, 107, 107, 0.1);
  
    --success: #00c28c;
    --success-100: rgb(0, 194, 140, 0.1);
  
    --pending: #FFC107;
    --pending-100: rgba(255, 193, 7, 0.1);

   --color-green: #4ade80; 
   --color-darkgreen: #3fb56a; 
}