
/* --- themes item slider css start --- */
.theme-slider-item {
    padding: 10px;
}

@media screen and (max-width:576px) {
    .theme-slider-item {
        padding: 10px 5px;
    }
}
/* ---themes item slider css  css end --- */



/* --- Themes item css start --- */
.themes-item-bx {
    display: block;
}

.themes-item-bx .tib-img-bx {
    position: relative;
    padding-top: 80%;
    width: 100%;
    border: 1px solid var(--black-100);
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    background-color: lightgray;
}

.themes-item-bx .tib-img-bx .tib-main-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    object-fit: contain;
    object-position: top center;
    transform-origin: top center;
    transition: all 0.3s ease-in-out;
}

.themes-item-bx:not(.tib-hover-off):hover .tib-img-bx .tib-main-img {
    transform: scale(1.02);
}

.themes-item-bx .tib-responsive-img {
    position: absolute;
    bottom: 0px;
    right: 20px;
    width: 100px;
    z-index: 1;
    background-color: var(--black);
    border-radius: 10px;
    padding: 5px;
    transform: translateY(30px);
    box-shadow: 0px -4px 8px 1px rgba(0, 0, 0, 0.07),
        0px -2px 4px 1px rgba(0, 0, 0, 0.07), 0px -1px 2px 1px rgba(0, 0, 0, 0.07);
    transition: all 0.3s ease-in-out;
}

.themes-item-bx:not(.tib-hover-off):hover .tib-responsive-img {
    transform: translateY(15px);
}

.themes-item-bx .tib-responsive-img::after {
    content: "";
    position: absolute;
    left: calc(100% - 1px);
    top: 10%;
    width: 3px;
    height: 15%;
    max-height: 36px;
    background-color: var(--black);
    z-index: 1;
    border-radius: 0 3px 3px 0;
}

.themes-item-bx .tib-responsive-img .tib-r-left {
    content: "";
    position: absolute;
    right: calc(100% - 1px);
    top: 10%;
    width: 3px;
    height: 30%;
    max-height: 72px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.themes-item-bx .tib-responsive-img .tib-r-left span {
    height: 100%;
    width: 100%;
    background-color: var(--black);
    border-radius: 3px 0 0 3px;
}

.themes-item-bx .tib-responsive-img img {
    width: 100%;
    border-radius: 5px;
}

.themes-item-bx .theme-name {
    font-size: 20px;
    font-weight: 600;
    margin-top: 5px;
    transition: all 0.3s ease-in-out;
}

.themes-item-bx:hover .theme-name {
    color: var(--lavender);
    text-decoration: underline;
}

.themes-item-bx .theme-price {
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;
}

.themes-item-bx .theme-review {
    color: var(--black-700);
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;
}

.themes-item-bx .theme-review svg {
    width: 18px;
    height: 18px;
    flex-basis: 18px;
    margin-right: 5px;
}

/* @media screen and (max-width: 768px) {
    .themes-item-bx .tib-img-bx {
        padding-top: initial;
        height: 320px;
    }
}

@media screen and (max-width: 576px) {
    .themes-item-bx .tib-img-bx {
        height: 280px;
    }
} */
/* --- Themes item css end --- */